import styled from 'styled-components';
import DefaultLayout from 'src/components/layouts/default';
import HomeNav from 'src/modules/home/shared/homeNav';
import WhatsYourQuestion from 'src/modules/home/shared/whatsYourQuestion';

type Props = {
  children: React.ReactNode;
  sidebarContent?: React.ReactNode;
};

export default function NewsfeedLayout({ children, sidebarContent }: Props): JSX.Element {
  return (
    <DefaultLayout>
      <Container >
        <WhatsYourQuestion classes="visible-xs" />
        <Sidebar>
          <HomeNav isNewsfeed={true} />
        </Sidebar>
        <Main>
          {children}
        </Main>
        <SecondSidebar>{sidebarContent}</SecondSidebar>
      </Container>
    </DefaultLayout>
  );
}

const Container = styled.div`
  display: flex;
  column-gap: 15px;
  margin-bottom: 60px;

  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 25%;

  @media (${({ theme }) => theme.underScreenMedium}) {
    width: 33%;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: auto;
  }
`;

const Main = styled.div`
  width: 50%;
  
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: 67%;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: auto;
    padding: 30px 0 15px;
  }
`;

const SecondSidebar = styled(Sidebar)`
  @media (${({ theme }) => theme.underScreenMedium}) {
    display: none;
  }
`;
